import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import Home from './pages/home'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact >
          <Home />
        </Route>
        <Route path="/*" render={() => (<Redirect to="/" />)} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
