import React from 'react';

import '../assets/css/home.css';

import logo from '../assets/images/logo.png';
import dp from '../assets/images/dp.jpg';

import saviynt from '../assets/images/saviynt.png';
import rockinterview from '../assets/images/rockinterview.png';
import smarterp from '../assets/images/smarterp.png';
import igrid from '../assets/images/igrid.png';

import visualresume from '../assets/images/visualresume.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faGlobe, faComments, faCommentDots, faUserAlt, faEnvelope, faMobileAlt, faBuilding, faArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import { Container, Row, Col } from 'react-bootstrap';

class Home extends React.Component {

  render() {
    document.title = "Karthik Ramesh | SWE | AIE | MLE";
    var exp = "";
    var doj = new Date('07/01/2011');
    var today = new Date();
    var days = parseInt(((today - doj) / ((1000 * 60 * 60 * 24))));
    exp += parseInt(days / 365.4) + " Years, ";
    exp += parseInt((days / 30.41666666) % 12) + " Months and ";
    exp += parseInt(days % 30.41666666) + " Days";
    return (
      <div className="home">
        <Container fluid>
        <Row className='head_blank'></Row>
          <Row className='head_bg'>
            <Col sm={12} md={12} lg={12} xl={2} xxl={2}>
              <div className='d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-start'>
                <div className='dp'>
                  <img src={dp} alt="dp" width="200" />
                  <img src={logo} alt="logo" width="100" />
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={5} xxl={5}>
              <Row className='head_data d-flex justify-content-start'><Col>
                <FontAwesomeIcon icon={faUserAlt} /><span>Name : <span className="name">Karthik Ramesh</span></span>
              </Col></Row>
              <Row className='head_data d-flex justify-content-start'><Col>
                <FontAwesomeIcon icon={faMobileAlt} /><span>Phone : <a target="_blank" rel="noreferrer" href="tel:+916362359803">+91 63623 59803</a></span>
              </Col></Row>
              <Row className='head_data d-flex justify-content-start'><Col>
                <FontAwesomeIcon icon={faEnvelope} /><span>Email : <a target="_blank" rel="noreferrer" href="mailto:rsk.cse1@gmail.com">rsk.cse1@gmail.com</a></span>
              </Col></Row>
              <Row className='head_data d-flex justify-content-start'><Col>
                <FontAwesomeIcon icon={faComments} /><span>WhatsApp : <a target="_blank" rel="noreferrer" href="https://wa.me/qr/4HYOTYPQ7ON5F1">https://wa.me/qr/4HYOTYPQ7ON5F1</a></span>
              </Col></Row>
            </Col>
            <Col sm={12} md={6} lg={6} xl={5} xxl={5}>
              <Row className='head_data d-flex justify-content-start'><Col>
                <FontAwesomeIcon icon={faGlobe} /><span>Linked In : <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/rskcse1">https://www.linkedin.com/in/rskcse1</a></span>
              </Col></Row>
              <Row className='head_data d-flex justify-content-start'><Col>
                <FontAwesomeIcon icon={faCode} /><span>Git Hub : <a target="_blank" rel="noreferrer" href="https://github.com/rskcse1">https://github.com/rskcse1</a></span>
              </Col></Row>
              <Row className='head_data d-flex justify-content-start'><Col>
                <FontAwesomeIcon icon={faCommentDots} /><span>WhatsApp ( Community Group ) : <a target="_blank" rel="noreferrer" href="https://chat.whatsapp.com/J0RIap7Cn6ZGLSlcevhJtW">https://chat.whatsapp.com/J0RIap7Cn6ZGLSlcevhJtW</a></span>
              </Col></Row>
              <Row className='head_data d-flex justify-content-start'><Col>
                <FontAwesomeIcon icon={faBuilding} /><span>Experience : <span><b>{exp}</b></span></span>
              </Col></Row>
            </Col>
          </Row>

          <Row className='heading'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <FontAwesomeIcon icon={faArrowsAlt} />
              <span>Carrier Path:</span>
            </Col>
          </Row>

          <Row className='data'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Row className='company_block'>
                <Col sm={12} md={12} lg={2} xl={2} xxl={2}>
                  <img src={saviynt} alt="Saviynt India" width="100%" />
                </Col>
                <Col sm={12} md={12} lg={10} xl={10} xxl={10}>
                  <div className='companyName'>Saviynt India Private Limited </div>
                  <Row className='company'>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <div><span>Location:</span><span> Bangalore </span></div>
                      <div><span>Role:</span><span> Associate Software Engineer (Division: Research and Development) </span></div>
                      <div><span>From:</span><span> From: Aug 2020, Till: Till Now</span></div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <div><span>Process:</span><span> Product - Application Design and Development (Domain: IGA) </span></div>
                      <div><span>Email Address:</span><span> <a target="_blank" rel="noreferrer" href="mailto:r.karthik@saviynt.com">r.karthik@saviynt.com</a></span></div>
                      <div><span>Website:</span><span> <a target="_blank" rel="noreferrer" href="https://saviynt.com/">https://saviynt.com/</a></span></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='company_block'>
                <Col sm={12} md={12} lg={2} xl={2} xxl={2}>
                  <img src={rockinterview} alt="Sri Krishna I-Tech" width="100%" />
                </Col>
                <Col sm={12} md={12} lg={10} xl={10} xxl={10}>
                  <div className='companyName'>Sri Krishna I-Tech (ASM Carrier Solutions) (Brand: RockInterview)</div>
                  <Row className='company'>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <div><span>Location:</span><span> Bangalore </span></div>
                      <div><span>Role:</span><span> Tech Lead </span></div>
                      <div><span>From:</span><span> From: Jan 2019, Till: Aug 2020</span></div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <div><span>Process:</span><span> Product - Design and Development </span></div>
                      <div><span>Email Address:</span><span> karthik.r@rockinterview.in (Inactive)</span></div>
                      <div><span>Website:</span><span> <a target="_blank" rel="noreferrer" href="https://rockinterview.in/">https://rockinterview.in/</a></span></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='company_block'>
                <Col sm={12} md={12} lg={2} xl={2} xxl={2}>
                  <img src={smarterp} alt="SmartERP Solutions" width="100%" />
                </Col>
                <Col sm={12} md={12} lg={10} xl={10} xxl={10}>
                  <div className='companyName'>SmartERP Solutions</div>
                  <Row className='company'>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <div><span>Location:</span><span> Bangalore </span></div>
                      <div><span>Role:</span><span> Senior Software Developer </span></div>
                      <div><span>From:</span><span> From: May 2015, Till: Dec 2018</span></div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <div><span>Process:</span><span> Service - Application Development </span></div>
                      <div><span>Email Address:</span><span> karthik.r@smarterp.com (Inactive)</span></div>
                      <div><span>Website:</span><span> <a target="_blank" rel="noreferrer" href="https://www.smarterp.com/">https://www.smarterp.com/</a></span></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='company_block'>
                <Col sm={12} md={12} lg={2} xl={2} xxl={2}>
                  <img src={igrid} alt="IGrid Consulting" width="100%" />
                </Col>
                <Col sm={12} md={12} lg={10} xl={10} xxl={10}>
                  <div className='companyName'>IGrid Consulting</div>
                  <Row className='company'>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <div><span>Location:</span><span> Chennai </span></div>
                      <div><span>Role:</span><span> Software Developer </span></div>
                      <div><span>From:</span><span> Jun 2011, Till: Apr 2015</span></div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                      <div><span>Process:</span><span> Service - Application Development (CMS) </span></div>
                      <div><span>Email Address:</span><span> rsk@igridconsulting.com (Inactive)</span></div>
                      <div><span>Website:</span><span> <a target="_blank" rel="noreferrer" href="https://www.igridconsulting.com/">https://www.igridconsulting.com/</a></span></div>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Col>
          </Row>

          <Row className='heading'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <FontAwesomeIcon icon={faArrowsAlt} />
              <span>Tech and Tool Stack:</span>
            </Col>
          </Row>

          <Row className='data'>
            <Col className='tech' sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className='techTitle'>Cloud Systems</div>
              <div>Amazon Web Services (AWS)</div>
              <div>Google Cloud Platform (GCP)</div>
              <div>Kubernetes (EKS, GKS)</div>
              <div>Helm Charts</div>
              <div>CI/CD Pipeline Deployments</div>
              <div>Storage, VPC</div>
            </Col>

            <Col className='tech' sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className='techTitle'>Dev Technologies / Tools</div>
              <div>Java SE, Java EE, Python</div>
              <div>Spring Boot, Micro-Services, Django</div>
              <div>Spring, Hibernate, JPA</div>
              <div>Intellij IDEA, Eclipse, Net Beans</div>
            </Col>

            <Col className='tech' sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className='techTitle'>Artificial Intelligence</div>
              <div>Natural Language Processing</div>
              <div>Google Analytics data processing</div>
              <div>Firebase ML Kit</div>
              <div>Django Channels</div>
              <div>Google Speech Recognition</div>
            </Col>

            <Col className='tech' sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className='techTitle'>Web Technologies</div>
              <div>JavaScript</div>
              <div>JQuery</div>
              <div>Bootstrap</div>
              <div>Angular</div>
              <div>React JS</div>
              <div>HTML5, HTML4</div>
              <div>CSS3,CSS2</div>
            </Col>

            <Col className='tech' sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className='techTitle'>Other Technologies / Tools</div>
              <div>Illustrator</div>
              <div>Photoshop</div>
              <div>Premier Pro</div>
              <div>Drupal (Basics)</div>
              <div>Hosting Public domains</div>
              <div>Windows and Linux Server setup</div>
              <div>Java Server and Domain management</div>
              <div>Basics of Networking</div>
            </Col>
          </Row>

          <Row className='heading'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <FontAwesomeIcon icon={faArrowsAlt} />
              <span>Projects:</span>
            </Col>
          </Row>

          <Row className='data'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Row className='project'>
                <Col className='projectItem' sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <div className='projectTitle'>Saviynt IGA Product</div>
                  <div className='projectDiscriptionTitle'>Description:</div>
                  <div className='projectDiscription'>Saviynt has Internet Governance Application for the use of Enterprise handling, which includes Onboarding, User management, Entitlement and more. Was a part of project up-gradation from Monolithic to Micro-Serviced design, product migration from EC2 to Kubernetes.</div>
                  <div className='projectResponsibilitiesTitle'>Responsibilities:</div>
                  <div className='projectResponsibilities'>
                    <span>Concept and Architecture design.</span>
                    <span>Setup and Maintenance of Scrum Environments.</span>
                    <span>Deployment of Java and Python projects using helm.</span>
                    <span>Technology analysis and design.</span>
                    <span>Project Management and delivery.</span>
                  </div>
                  <div className='projectEnvironmentTitle'>Environment:</div>
                  <div className='projectEnvironment'>
                    <span>AWS EC2</span>
                    <span>Kubernetes</span>
                    <span>Helm (Linux)</span>
                  </div>
                </Col>
                <Col className='projectItem' sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <div className='projectTitle'>Resume Analysis</div>
                  <div className='projectDiscriptionTitle'>Description:</div>
                  <div className='projectDiscription'>Resume Analysis is a tool which is used to split the resume and convert the data into structured format for better analysis, the core purpose of the tool is to create a data frame from the resume keywords and provide better result set for Employer during search. </div>
                  <div className='projectResponsibilitiesTitle'>Responsibilities:</div>
                  <div className='projectResponsibilities'>
                    <span>Concept and Architecture design.</span>
                    <span>Setup and Maintenance of Production Server.</span>
                    <span>Production deployment of Python and Java packages.</span>
                    <span>Technology analysis and design.</span>
                    <span>Project Management and delivery.</span>
                  </div>
                  <div className='projectEnvironmentTitle'>Environment:</div>
                  <div className='projectEnvironment'>
                    <span>AWS EC2 (Linux)</span>
                  </div>
                </Col>
                <Col className='projectItem' sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <div className='projectTitle'>Training Suggestion</div>
                  <div className='projectDiscriptionTitle'>Description:</div>
                  <div className='projectDiscription'>Training Suggestion is process of suggesting tutorials and trainings required for an improvement of the performance to the Job Seeker or the Interviewer, based on the feedback provided by the job seeker or the interviewer a sentiment analysis is been performed and as a result a few tutorials are suggested to the job seeker and interviewer.</div>
                  <div className='projectResponsibilitiesTitle'>Responsibilities:</div>
                  <div className='projectResponsibilities'>
                    <span>Approach Design and Implementation.</span>
                    <span>Setup, Deployment and Maintenance of Production Server.</span>
                    <span>App Data Integration design.</span>
                    <span>Technology analysis and design.</span>
                    <span>Project Management and delivery.</span>
                  </div>
                  <div className='projectEnvironmentTitle'>Environment:</div>
                  <div className='projectEnvironment'>
                    <span>AWS EC2 / Linux (Apache Server)</span>
                  </div>
                </Col>
                <Col className='projectItem' sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <div className='projectTitle'>Probability of Joining</div>
                  <div className='projectDiscriptionTitle'>Description:</div>
                  <div className='projectDiscription'>POJ is a process of finding a right candidate before the offer is released by the Employer, it has a huge data integration and analysis like resume process, training data and search history with employer data.</div>
                  <div className='projectResponsibilitiesTitle'>Responsibilities:</div>
                  <div className='projectResponsibilities'>
                    <span>Architecture Design and Development.</span>
                    <span>Data Research.</span>
                    <span>Algorithm Design.</span>
                    <span>Production and QA environment setup.</span>
                    <span>Deployments and Updates.</span>
                  </div>
                  <div className='projectEnvironmentTitle'>Environment:</div>
                  <div className='projectEnvironment'>
                    <span>AWS EC2</span>
                  </div>
                </Col>
                <Col className='projectItem' sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <div className='projectTitle'>Data Analysis</div>
                  <div className='projectDiscriptionTitle'>Description:</div>
                  <div className='projectDiscription'>Data Analysis is an approach of executing Machine Learning models with one click action, where the portal will analyze the data and suggest the user on what are the operations required for data frame design like Normalization etc… and create models for actual data analysis.</div>
                  <div className='projectResponsibilitiesTitle'>Responsibilities:</div>
                  <div className='projectResponsibilities'>
                    <span>Development of Data Analysis and suggestion algorithm.</span>
                    <span>Django based API creation for an options suggestion.</span>
                    <span>Development and customization on most modules.</span>
                    <span>Production and QA environment setup.</span>
                    <span>Deployments and Updates.</span>
                  </div>
                  <div className='projectEnvironmentTitle'>Environment:</div>
                  <div className='projectEnvironment'>
                    <span>GCP CE</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='heading'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <FontAwesomeIcon icon={faArrowsAlt} />
              <span>Summary:</span>
            </Col>
          </Row>

          <Row className='data'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className='summary'>
                <span>Over {parseInt(days / 365.4)}+ years of IT experience in various Development and Web Design which also includes System Analysis, Design, Development and Support of technologies like Java, Python, Angular and Bootstrap.</span>
                <span>Over {parseInt(days / 365.4) - 2} years of experience in Java, Spring, Spring Boot, Micro Service.</span>
                <span>Strong {parseInt(days / 365.4) - 3} years of experience in Rest Services creation using Java Spring Boot and Python Django Rest Framework.</span>
                <span>Over {parseInt(days / 365.4) - 4} years of experience in Java web development using Spring Boot and Angular</span>
                <span>Over {parseInt(days / 365.4) - 7} years of experience in Python web development using Django and React JS.</span>
                <span>Over {parseInt(days / 365.4) - 9} years of experience in AI programming using Python and Java.</span>
                <span>Strong knowledge on Helm, Pipelines, Deployments.</span>
                <span>Strong knowledge on UNIX/LINUX systems.</span>
                <span>Strong knowledge on Dockers and Kubernetes.</span>
                <span>Strong knowledge on product design using micro services.</span>
                <span>Strong Knowledge in maven package creation and updates.</span>
                <span>Strong Knowledge on Data Analysis, Data Processing and Data Visualization.</span>
                <span>Strong skills on Data Frame design using Python.</span>
                <span>Proficient in setting up PROD and QA environments on AWS for Python and Java.</span>
                <span>Proficiency in JIRA environment configuration and project setup.</span>
                <span>Strong Knowledge on handling project using scrum model.</span>
                <span>Expertise on approach designing for complex applications.</span>
                <span>Good knowledge on Product designing with audience analysis.</span>
                <span>Good knowledge on handling data from other sources like Oracle, SQL Server, MySQL, etc.</span>
                <span>Excellent Team Building, Analytical, Interpersonal & Communication Skills.</span>
                <span>Highly Developed Written and Oral Communication Skills.</span>
                <span>Excellent Interpersonal Skills to Complement Technical Skills.</span>
              </div>
            </Col>
          </Row>

          <Row className='heading'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <FontAwesomeIcon icon={faArrowsAlt} />
              <span>Awards / Achievements and Certifications:</span>
            </Col>
          </Row>

          <Row className='data'>
            <Col className='highlight' sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className='highlightTitle'>Awards</div>
              <div>Employee of the Month (May 2022)</div>
              <div>SPOT Performer</div>
              <div>IronMan</div>
              <div>Client's Best Performer</div>
            </Col>
            <Col className='highlight' sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className='highlightTitle'>Achievements</div>
              <div>Completed Critical tasks in Short Time</div>
              <div>Migrated Product from OnPrem to Cloud</div>
              <div>Built Multiple Plugins for various usage</div>
            </Col>
            <Col className='highlight' sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className='highlightTitle'>Certifications</div>
              <div><a target="_blank" rel="noreferrer" href="https://certificate.algoexpert.io/SE-337dc66e66">System Design (AlgoExpert)</a></div>
            </Col>
          </Row>

          <Row className='heading'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <FontAwesomeIcon icon={faArrowsAlt} />
              <span>Grphical Profile:</span>
            </Col>
          </Row>

          <Row className='data'>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <img src={visualresume} alt="Visual Resume" width="100%" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Home;